<template>
    <div id="production" class="page-wrapper page-production">
        <!-- SubHero -->
        <header class="sub-hero site-max-width" hero-transition-group data-gsap-change-color="default">
            <div class="container">
                <h1 class="small-title title" data-inview="fadeInUp" data-delay="100">Production</h1>
                <p class="big-text text" data-inview="fadeInUp" data-delay="200">
                    Des productions de calibre <br />
                    international, sans avoir à sortir <br />
                    de Montréal.
                </p>
                <img src="@/assets/imgs/production-hero.jpg" alt="Production" class="main" data-inview="fadeInUp" data-delay="500" />
            </div>
        </header>

        <!-- Content -->
        <div
            id="zone-production"
            class="content-text production-wrap no-border site-max-width"
            data-bg-5
            data-gsap-change-color="colorAccent5"
            hero-transition-group
        >
            <div class="container" data-gsap-change-color="colorAccent5">
                <div class="spacer"></div>
                <div class="top">
                    <h2 class="big-title title">
                        On a les moyens de nos ambitions. <br/> Et des vôtres.
                    </h2>
                </div>
                <article class="top-border" data-gsap-change-color="colorAccent5">
                    <div class="cols">
                        <div class="left">
                            <h3 class="small-title">La méthodologie</h3>
                        </div>
                        <div class="right">
                            <p class="small-text right-text">
                                Nos producteurs prennent en charge les projets de A à Z, et ce, peu importe l’envergure. Ils sont là de la planification à la livraison du produit final. Ils connaissent les SPECS sur le bout de leurs doigts et font arriver des choses qu’on n'aurait pas cru possibles en passant à travers chaque étape dans le temps de le dire : estimation budgétaire, sélection des talents et des partenaires, organisation du tournage, montage, déclinaison, suivi, approbation. Ils vont même penser à ton petit lunch le jour du shoot. C’est littéralement un tout inclus avec eux.
                                <br /><br />
                                Et quand on dit production, on ne parle pas seulement d’un drap blanc et d’un appareil photo…
                            </p>
                        </div>
                    </div>

                    <div class="scroll-words">
                        <div class="scroller" data-gsap-scroll-left>
                            <div class="auto-track">
                                <p class="word">Vidéo</p>
                                <p class="word">Montage</p>
                                <p class="word">Magazine&nbsp;imprimé</p>
                                <p class="word">Direction&nbsp;artistique</p>
                                <p class="word">Radio</p>
                                <p class="word">Photo</p>
                                <p class="word">Motion&nbsp;design</p>
                                <p class="word">Image&nbsp;de&nbsp;marque</p>
                                <p class="word">Design&nbsp;sonore</p>
                                <p class="word">Réalisation</p>
                                <p class="word">Animation&nbsp;3D</p>
                                <p class="word">Traduction</p>
                                <p class="word">Retouche</p>
                                <p class="word">Illustration</p>
                                <p class="word">Production&nbsp;numérique</p>
                                <p class="word">Relecture</p>
                            </div>
                        </div>
                    </div>
                </article>
                <div class="lone-img">
                    <div data-inview="revealRight" data-color="colorAccent5" data-delay="100"><img src="@/assets/imgs/production-reel.jpg" alt="Production" /></div>

                    <div class="content">
                        <a href="https://vimeo.com/706689246" class="glightbox" >
                            <h2 class="video-title big-title title" data-inview="fadeInUp" data-delay="100">
                                Voyez comment ça se passe en production par chez nous.
                                <br />
                                <svg
                                    class="play-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="75.347"
                                    height="75.348"
                                    viewBox="0 0.026 75.347 75.348"
                                    data-inview="fadeInUp" data-delay="200"
                                >
                                    <g>
                                        <path
                                            d="M30.733 56.192V18.54c0-.983.591-1.868 1.499-2.244a2.427 2.427 0 0 1 .788-.18c.047-.003.094-.005.14-.005a2.424 2.424 0 0 1 1.718.712L53.704 35.65a2.416 2.416 0 0 1 .664 1.239 2.425 2.425 0 0 1-.664 2.195L34.878 57.91a2.428 2.428 0 0 1-1.718.712 2.414 2.414 0 0 1-.163-.006 2.423 2.423 0 0 1-.765-.18 2.428 2.428 0 0 1-1.5-2.243Zm4.857-31.789v25.925l12.962-12.962L35.59 24.403Z"
                                            fill="#ceffb4"
                                            fill-rule="evenodd"
                                            data-name="Path 14"
                                        />
                                        <path
                                            d="M37.674-.474c20.85 0 37.673 16.824 37.673 37.674a37.553 37.553 0 0 1-37.673 37.674A37.483 37.483 0 0 1 .004 37.813 39.296 39.296 0 0 1 0 37.2 37.554 37.554 0 0 1 37.674-.474Zm0 70.496c18.063 0 32.72-14.655 32.72-32.822 0-18.063-14.657-32.72-32.72-32.72C19.51 4.48 4.851 19.137 4.851 37.2a32.773 32.773 0 0 0 32.823 32.822Z"
                                            fill="#ceffb4"
                                            fill-rule="evenodd"
                                            data-name="Path 13"
                                        />
                                    </g>
                                </svg>
                            </h2>
                        </a>
                    </div>
                </div>
                <article class="bottom-border" data-gsap-change-color="colorAccent5">
                    <div class="cols">
                        <div class="left" data-inview="fadeInUp" data-delay="100">
                            <h3 class="small-title">La production</h3>
                        </div>
                        <div class="right" data-inview="fadeInUp" data-delay="200">
                            <p class="small-text right-text">
                                Chez nous, on accorde une importance capitale à la production, car on a pour notre dire qu’une bonne idée mal réalisée devient une mauvaise idée. On souhaite offrir à nos partenaires une qualité de production hors pair qui fera briller nos idées.
                                <br/><br/>
                                C’est grâce au talent et à l’agilité de nos équipes que nos productions se distinguent. Avec plus de 30 ans d’expérience derrière la cravate, on a compris que la clé est de s’entourer de gens touche-à-tout qui en ont vu d’autres. On ne dit pas ça pour se vanter, mais nos producteurs savent tout faire. On dirait des magiciens.
                                <br/><br/>
                                Et nos magiciens ont plus d’un tour dans leur sac. Comme on est issus du plus grand producteur de contenu francophone en Amérique, disons qu’on ne manque pas de ressources. Question infrastructure et talent, nos capacités sont quasi illimitées. On a environ 20 studios montréalais sur speed dial, et le MELS sur la 1.
                            </p>
                        </div>
                    </div>
                </article>
                <div class="spacer"></div>
                <div class="testimonial" data-gsap-change-color="colorAccent5">
                    <p class="big-text" data-inview="fadeInUp" data-delay="100">
                        « On peut faire à peu près n’importe quoi entre un post Facebook organique et le prochain film de Marvel. »
                    </p>
                    <p class="small-text" data-inview="fadeInUp" data-delay="200">— Lucie Gauthier, directrice principale production commerciale.</p>
                    <img src="@/assets/imgs/icon-smile.png" alt="Smile" class="float-icon" data-gsap-parrallax-scroll>
                </div>
            </div>
        </div>

        <!-- Footer Nav -->
        <router-link :to="{ name: 'Creativite-fr' }" class="full-video footer-nav" data-gsap-activate-footer>
            <div class="container">
                <img class="main" src="@/assets/imgs/production-footer-nav.jpg" alt="Créa" />
                <div class="content">
                    <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                        Toutes nos productions partent de quelque part. Voyez ce qu’on fait en créa.

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            viewBox="139 15409.606 95.627 88.742"
                        >
                            <path
                                d="m190.256 15409.607-4.973 4.973 35.829 35.828H139v7.012h82.239l-35.956 35.956 4.973 4.973 44.37-44.371-44.37-44.371Z"
                                fill="#ceffb4"
                                fill-rule="evenodd"
                                data-name="Path 471"
                            />
                        </svg>
                    </h2>
                    <!-- svg -->
                </div>
            </div>
        </router-link>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import GLightbox from 'glightbox'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(ScrollToPlugin)

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Production',

    components: {
        Footer,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        setTimeout(() => {
                            this.initGsap()
                            this.initGsapBgColors()

                            this.gsapActivateFooter()

                            gsap.utils.toArray('[data-gsap-parrallax-scroll]').forEach(element => {
                                gsap.to(element, {
                                    scrollTrigger: {
                                        trigger: element,
                                        start: 'top bottom',
                                        end: 'bottom top',
                                        scrub: 1.475,
                                    },
                                    y: '-25%',
                                    ease: 'none',
                                })
                            })

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {}
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initGsap() {
            gsap.utils.toArray('[data-gsap-scroll-left]').forEach(track => {
                gsap.to(track, {
                    scrollTrigger: {
                        trigger: track,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 1.475,
                    },
                    x: '-25%',
                    ease: 'none',
                })
            })
        },
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 60%',
                    end: 'bottom 30%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-production')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-production').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-production').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-production')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-production').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-production').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-production')) {
                        return;
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-production')) {
                        return;
                    }
                    footerFold.classList.remove('is-active')
                },
            })
        }
    },
}
</script>
